<script lang="ts" setup>
import { useIndexStore } from '@/stores/index';
const index = useIndexStore()
</script>

<template>
  <v-overlay
    v-model="index.Loading"
    class="align-center justify-center"
  >
    <div class="animate-bounce">
      <SvgoAgentHomeLogo class="!w-[148px] !h-[62px] mx-auto" filled/>
    </div>
  </v-overlay>
</template>

<style scoped></style>
