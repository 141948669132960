<script setup lang="ts">
// useAuth() for username and password
const { data } = useAuth()
</script>

<template>
  <v-app>
    <AppHeader />
    <v-main>
      <LazyShieldGray v-if="data?.shield == 'gray'" />
      <LazyShieldGreen v-else-if="data?.shield == 'green'" />
      <slot v-else />
    </v-main>
    <AppLoading />
  </v-app>
  <ShareModalStatus />
</template>

<style scoped></style>
