<script lang="ts" setup>
import { useIndexStore } from '@/stores/index'
import { useGetColorShield } from '@/composables/functions/useGetColorShield'
import { getImage } from '@/composables/functions/useImageProxy'
// import liff from '@line/liff';

//Intitial
const config = useRuntimeConfig()
const index = useIndexStore();
const { signOut, data } = useAuth();
const route = useRoute()

//Define Data
const drawer = ref(false)
const rail = ref(false)
const dialogLogout = ref(false)

//Function
const logout = async (e: any) => {
  try {
    e.preventDefault()
    await signOut({
      callbackUrl: '/auth/login',
      redirect: true
    })
    // liff.init({
    //   liffId: config.public.liffId
    // }).then(() => {
    //   liff.closeWindow()
    // })
    index.$patch((state) => {
      state.showDialog = false
      state.firstLogin = false
      state.statusCode = 0
      state.msg = ''
    })
  } catch (error: any) { // Add after logout logic here, could be removing the session data from localstorage or whatever
    return 'error'
  }
};
const activePath = (root: string) => {
  const currentPath = route.fullPath
  if (currentPath.startsWith(root)) {
    return true
  } else {
    return false
  }
}

onMounted(() => {
  index.$patch((state) => {
    state.notiRefer = data.value?.notificationReceiveRefer
  })
})
</script>

<template>
  <section>
    <v-navigation-drawer
      v-if="$vuetify.display.mdAndUp || $vuetify.display.lgAndUp"
      :rail="rail"
      :permanent="$vuetify.display.mobile"
    >
      <v-list nav class="border-b border-b-gray-600">
        <v-list-item role="listitem" aria-labelledby="menu">
          <template v-slot:prepend>
            <v-icon v-if="rail" @click.stop="rail = !rail">
              mdi-menu
            </v-icon>
            <SvgoWebLogo v-else  class="!w-[74px] !h-[31px] cursor-pointer" filled @click="navigateTo('/agent/my-refers')"/>
          </template>
          <template v-slot:append>
            <v-icon @click.stop="rail = !rail">
              mdi-menu
            </v-icon>
          </template>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <div>
        <div class="flex flex-col items-center pt-4 pb-6 px-4 gap-2">
          <v-avatar size="40">
            <nuxt-img
              v-if="data?.profile_image_path"
              :src="getImage(`${config.public.apiBase}/api/s3/${data?.profile_image_path}`, false, 120, 120)"
              :alt="data?.name"
              loading="lazy"
              sizes="100vw sm:50vw md:120px"
              format="webp"
            ></nuxt-img>
            <nuxt-img
              v-else
              src="/dummy/user.webp"
              :alt="data?.name"
              format="webp"
            ></nuxt-img>
          </v-avatar>
          <div v-if="rail === false" class="flex flex-col items-center w-full">
            <div class="flex gap-2 items-center">
              <div class="text-lg font-normal text-center">{{ data?.name }}</div>
              <SvgoShield class="!w-[16px] !h-[16px] !mb-0 flex-none" :class="`text-${useGetColorShield(data?.shield)}`" filled/>
            </div>
            <div class="flex gap-1 text-base font-normal text-grey-4">
              {{ data?.role }}
              <SvgoVerified v-if="(data?.approved === 3 && data?.shield === 'blue') || (data?.approved === 1 && data?.shield === 'green')" class="!w-[24px] !h-[24px]" filled/>
            </div>
            <!-- <div class="w-full pt-2">
              <v-btn
                block
                height="48px"
                color="#16C799"
                rounded="lg"
                elevation="0"
              >
                <template v-slot:prepend>
                  <SvgoCreatePost class="!w-[20px] !h-[20px]" filled/>
                </template>
                <div class="text-base font-normal text-white">สร้างใบประกาศ</div>
              </v-btn>
            </div> -->
          </div>
        </div>
        <v-list nav :class="!rail ? '!px-4' : ''" color="primary-facebook">
          <!-- <v-list-item role="listitem" aria-labelledby="myPosts" class="mb-4">
            <template v-slot:prepend>
              <SvgoMyPost class="!w-[20px] !h-[20px] v-icon !opacity-100" filled/>
            </template>
            <template v-slot:title>
              <div class="text-lg font-normal">
                ประกาศของฉัน
              </div>
            </template>
          </v-list-item> -->
          <v-list-item v-if="!rail" role="listitem" aria-labelledby="fromNayoo">
            <template v-slot:title>
              <div class="text-lg font-normal text-grey-4">
                รับงานจากน่าอยู่
              </div>
            </template>
          </v-list-item>
          <!-- <v-list-item v-else role="listitem" aria-labelledby="listItem" class="mt-4 !px-0">
            <v-divider :thickness="3" class="border-grey-4 border-opacity-100"></v-divider>
          </v-list-item> -->
          <v-list-item role="listitem" aria-labelledby="refers" to="/agent/refers" :active="activePath('/agent/refers')" class="mt-4">
            <template v-slot:prepend>
              <v-icon class="!opacity-100">
                mdi-tag-outline
              </v-icon>
              <v-badge
                v-if="rail && index.NotiRefer > 0 && (data?.shield !== 'green' || data?.shield !== 'gray')"
                color="error"
                offset-y="-10"
                :content="index.NotiRefer"
              ></v-badge>
            </template>
            <template v-slot:title>
              <div class="text-lg font-normal flex items-center">
                ข้อเสนอทรัพย์
                <span v-if="!rail && index.NotiRefer > 0 && (data?.shield !== 'green' || data?.shield !== 'gray')" class="ml-2 pb-[2px] relative w-5 h-5 bg-error rounded-full flex justify-center items-center text-sm">{{ index.NotiRefer }}</span>
              </div>
            </template>
          </v-list-item>
          <v-list-item role="listitem" aria-labelledby="myRefers" to="/agent/my-refers" :active="activePath('/agent/my-refers')" class="mt-4">
            <template v-slot:prepend>
              <SvgoReferList class="!w-[24px] !h-[24px] v-icon !opacity-100" filled/>
            </template>
            <template v-slot:title>
              <div class="text-lg font-normal">
                ทรัพย์ที่ได้รับ
              </div>
            </template>
          </v-list-item>
        </v-list>
      </div>
      <template v-slot:append>
        <v-list nav :class="!rail ? '!px-4' : ''">
          <v-list-item role="listitem" aria-labelledby="logout" @click="dialogLogout = !dialogLogout">
            <template v-slot:prepend>
              <v-icon color="red" class="!opacity-100">
                mdi-logout
              </v-icon>
            </template>
            <template v-slot:title>
              <div class="text-lg font-normal">
                ออกจากระบบ
              </div>
            </template>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar elevation="0" class="border-b">
      <template v-slot:append>
        <v-btn v-if="!$vuetify.display.smAndUp && $vuetify.display.smAndDown" aria-labelledby="menu" icon="mdi-menu" @click.stop="drawer = !drawer"></v-btn>
        <!-- <div v-if="$vuetify.display.smAndUp" class="flex gap-2 items-center">
          <div class="flex flex-col items-center">
            <div>
              <v-chip variant="tonal" size="small" :color="useGetColorShield(data?.shield)">
                {{ data?.role }}
              </v-chip>
            </div>
            <div class="text-base font-normal">{{ data?.name }}</div>
          </div>
          <v-avatar size="40">
            <nuxt-img
              v-if="data?.profile_image_path"
              :src="getImage(`${config.public.apiBase}/api/s3/${data?.profile_image_path}`, false, 120, 120)"
              :alt="data?.name"
              loading="lazy"
              sizes="100vw sm:50vw md:120px"
            ></nuxt-img>
            <nuxt-img
              v-else
              src="/dummy/user.webp"
              :alt="data?.name"
            ></nuxt-img>
          </v-avatar>
        </div> -->
      </template>
      <template v-slot:prepend>
        <v-btn v-if="$vuetify.display.smAndUp && $vuetify.display.smAndDown" aria-labelledby="menu" icon="mdi-menu" @click.stop="drawer = !drawer" class="mr-4"></v-btn>
        <SvgoWebLogo v-if="$vuetify.display.smAndDown" class="!w-[74px] !h-[31px] cursor-pointer" filled @click="navigateTo('/agent/my-refers')"/>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.display.mdAndDown"
      v-model="drawer"
      location="left"
      width="260"
    >
      <div>
        <div class="flex flex-col items-center pt-4 pb-6 px-4 gap-2">
          <v-avatar size="40">
            <nuxt-img
              v-if="data?.profile_image_path"
              :src="getImage(`${config.public.apiBase}/api/s3/${data?.profile_image_path}`, false, 120, 120)"
              :alt="data?.name"
              loading="lazy"
              sizes="100vw sm:50vw md:120px"
              format="webp"
            ></nuxt-img>
            <nuxt-img
              v-else
              src="/dummy/user.webp"
              :alt="data?.name"
              format="webp"
            ></nuxt-img>
          </v-avatar>
          <div class="flex flex-col items-center w-full">
            <div class="flex gap-2 items-center">
              <div class="text-lg font-normal text-center">{{ data?.name }}</div>
              <SvgoShield class="!w-[16px] !h-[16px] !mb-0 flex-none" :class="`text-${useGetColorShield(data?.shield)}`" filled/>
            </div>
            <div class="flex gap-1 text-base font-normal text-grey-4">
              {{ data?.role }}
              <SvgoVerified v-if="(data?.approved === 3 && data?.shield === 'blue') || (data?.approved === 1 && data?.shield === 'green')" class="!w-[24px] !h-[24px]" filled/>
            </div>
            <!-- <div class="w-full pt-2">
              <v-btn
                block
                height="48px"
                color="#16C799"
                rounded="lg"
                elevation="0"
              >
                <template v-slot:prepend>
                  <SvgoCreatePost class="!w-[20px] !h-[20px]" filled/>
                </template>
                <div class="text-base font-normal text-white">สร้างใบประกาศ</div>
              </v-btn>
            </div> -->
          </div>
        </div>
        <v-list nav class="!px-4" color="primary-facebook">
            <!-- <v-list-item role="listitem" aria-labelledby="myPosts" class="mb-4">
              <template v-slot:prepend>
                <SvgoMyPost class="!w-[20px] !h-[20px] v-icon !opacity-100" filled/>
              </template>
              <template v-slot:title>
                <div class="text-lg font-normal">
                  ประกาศของฉัน
                </div>
              </template>
            </v-list-item> -->
            <v-list-item role="listitem" aria-labelledby="fromNayoo">
              <template v-slot:title>
                <div class="text-lg font-normal text-grey-4">
                  รับงานจากน่าอยู่
                </div>
              </template>
            </v-list-item>
            <v-list-item role="listitem" aria-labelledby="refers" to="/agent/refers" :active="activePath('/agent/refers')" @click="drawer = !drawer" class="mt-4">
              <template v-slot:prepend>
                <v-icon class="!opacity-100">
                  mdi-tag-outline
                </v-icon>
              </template>
              <template v-slot:title>
                <div class="text-lg font-normal flex items-center">
                  ข้อเสนอทรัพย์
                  <span v-if="index.NotiRefer > 0 && (data?.shield !== 'green' || data?.shield !== 'gray')" class="ml-2 pb-[2px] relative w-5 h-5 bg-error rounded-full flex justify-center items-center text-sm">{{ index.NotiRefer }}</span>
                </div>
              </template>
            </v-list-item>
            <v-list-item role="listitem" aria-labelledby="myRefers" to="/agent/my-refers" :active="activePath('/agent/my-refers')" @click="drawer = !drawer" class="mt-4">
              <template v-slot:prepend>
                <SvgoReferList class="!w-[24px] !h-[24px] v-icon !opacity-100" filled/>
              </template>
              <template v-slot:title>
                <div class="text-lg font-normal">
                  ทรัพย์ที่ได้รับ
                </div>
              </template>
            </v-list-item>
          </v-list>
      </div>
      <template v-slot:append>
        <v-list nav class="!px-4">
          <v-list-item role="listitem" aria-labelledby="logout" @click="dialogLogout = !dialogLogout; drawer = !drawer">
            <template v-slot:prepend>
              <v-icon color="red" class="!opacity-100">
                mdi-logout
              </v-icon>
            </template>
            <template v-slot:title>
              <div class="text-lg font-normal">
                ออกจากระบบ
              </div>
            </template>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- Logout Dialog -->
    <v-dialog
      v-model="dialogLogout"
      persistent
      max-width="295"
    >
      <v-card max-width="295" class="!rounded-lg">
        <v-card-text class="flex flex-col justify-center !p-6 gap-4">
          <div class="flex justify-center">
            <SvgoConfirmLoguot class="!w-[200px] !h-[184px]" filled/>
          </div>
          <div class="text-base text-center">คุณต้องการออกจากระบบใช่หรือไม่?</div>
          <div class="flex justify-center gap-4">
            <v-btn width="115" height="40" color="primary-facebook" variant="outlined" rounded="lg" @click="dialogLogout = !dialogLogout">
              <div class="text-lg font-normal">ปิด</div>
            </v-btn>
            <v-btn width="115" height="40" color="primary-facebook" rounded="lg" elevation="0" @click="logout">
              <div class="text-lg font-normal">ยืนยัน</div>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<style scoped></style>
